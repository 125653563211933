<template>
    <div>
		<el-cascader size="small" v-model="cityIdSelect" :filterable="true" placeholder="输入城市选择机构" :props="citys" clearable
        @change="cityChange"></el-cascader>
        <div v-show="cityIdSelect.length > 0">
            <div v-loading="loading">
                <div>
                    <el-input style="width: 200px;margin-right:10px;" size="small" v-model="orgSearch" placeholder="输入机构名搜索" clearable></el-input>
                    <el-button size="small" @click="searchOrg()">搜索</el-button>
                </div>
                <div v-if="orgList.length<=0" style="color:red">当前城市没有机构</div>
                <template v-else>
                    <!-- 机构多选  -->
                    <el-checkbox-group v-if="isMore == 'isMore'" v-model="org_selectlist" style="max-height: 300px; overflow-y:auto" @change="selectlistOrg()">
                        <el-checkbox style="display:block" v-for="item in orgList" :key="item.org_id" :label="item">{{ item.org_name || '--' }}</el-checkbox>
                    </el-checkbox-group>
                    <!-- 机构单选 -->
                    <el-radio-group v-else v-model="org_id" style="max-height: 300px; overflow-y:auto" @change="selectOrg()">
                        <el-radio style="display:block" v-for="item in orgList" :key="item.org_id" :label="item.org_id">{{ item.org_name || '--' }}</el-radio>
                    </el-radio-group>
                </template>
                <el-pagination v-if="orgListTotal > limit" style="padding: 6px 0" @current-change="handleCurrentChange" 
                    :current-page.sync="orgcurrentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="orgListTotal">
                </el-pagination>
            </div>
        </div>
	</div>
</template>
<script>
import { simpleorgList } from '@/api/institutional'
export default {
    name: "OrgSelect",
    props: {
        isMore: { // 多选
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loading: true,
            citys: {}, // 城市列表
            cityIdsearch: 0, // 筛选城市
            cityIdSelect: [], // 选中城市
            orgList: [], // 机构列表
            limit: 10, // 机构列表一页多少条数据
            orgcurrentPage: 1, // 机构列表当前页
            orgListTotal: 0, // 机构列表总页
            orgSearch: '', // 筛选机构名
            org_id: '', // 选中机构
            org_selectlist: [] // 多选选中机构
        }
    },
    created() {
        this.setCity()
    },
    methods: {
        // 动态设置城市  后台接口没有直接全部返回只能用动态加载
        setCity() {
            let _this = this
            this.citys = {
                value: 'id',
                label: 'ShortName',
                lazy: true,
                lazyLoad(node,resolve) {
                    _this.$constant.getcity().then((res) => {
                        if (node.level === 0) {
                            resolve(res);
                        } else if (node.level === 1) {
                            _this.$constant.getcity(node.value).then((res2) => {
                                resolve(res2);
                            })
                        }
                    })
                }
            }
        },
        // 选择城市
        cityChange(val) {
            this.loading = true
            this.cityIdsearch = val[1]
            this.getorgList(val[1])
        },
        // 获取机构
        getorgList(id) {
            simpleorgList({
                limit: this.limit,
                page: this.orgcurrentPage,
                city_id: id || this.cityIdsearch,
                org_name: this.orgSearch || null,
            }).then((res) => {
                this.orgList = res.data.data
                this.orgListTotal = res.data.total
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        },
        // 搜索机构
        searchOrg() {
            this.loading = true
            this.orgcurrentPage = 1
            this.getorgList()
        },
        // 页码修改
        handleCurrentChange(val) {
            this.loading = true
            this.orgcurrentPage = val;
            this.getorgList();
        },
        // 多选选中机构
        selectlistOrg() {
            this.$emit('selectOrg', this.org_selectlist)
        },
        // 选中机构
        selectOrg(){
            this.$emit('selectOrg', this.org_id)
        },
    }
};
</script>