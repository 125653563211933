<template>
	<div class="wrapper">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px">
            <el-form-item label="添加机构：">
                <el-button size="small" @click="dialogVisible = true">添加机构</el-button>
                <div class="card" shadow="never">
                    <div class="width100 widthblod">
                        <div class="width20">机构id</div>
                        <div class="width20">所在城市</div>
                        <div class="width20">机构名称</div>
                        <!-- <div class="width20">上架视频数量</div> -->
                        <div class="width20">操作</div>
                    </div>
                    <div class="width100" v-for="(item,index) in sureList" :key="index">
                        <div class="width20">{{item.org_id || '----'}}</div>
                        <div class="width20">{{item.org_name || '----'}}</div>
                        <div class="width20">{{item.city || '----'}}</div>
                        <!-- <div class="width20">{{item.list || '----'}}</div> -->
                        <div class="width20"><el-link type="primary" @click="deleteList(index)">取消</el-link></div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="本地添加点赞数量：" prop="num">
                <el-input-number size="small" v-model="ruleForm.num" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label="设置点赞时间段" prop="start_end_time">
                <el-date-picker
                    size="small"
                    v-model="ruleForm.start_end_time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="onSubmit('ruleForm')">开始生成</el-button>
            </el-form-item>
        </el-form>
        <el-dialog title="选中机构" :visible.sync="dialogVisible" width="600px" :before-close="close" :close-on-click-modal='false'>
            <OrgSelect :isMore="'isMore'" @selectOrg="selectOrg"/>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addOrg()">确 定</el-button>
            </span>
        </el-dialog>
	</div>
</template>
<script>
    import OrgSelect from '@/components/orgSelect.vue'
	import { autoSchoolVote } from '@/api/courseOrder.js';
	export default {
		name: "VirtualLike",
		data() {
			return {
                list: [],
                sureList: [],
                ruleForm: {
                    num: '',
                    start_end_time: ''
                },
                rules: {
					num: [
						{ required: true, message: '请添加点赞数量', trigger: 'blur' }
                    ],
                    start_end_time: [
						{ required: true, message: '请设置点赞时间段', trigger: 'change' }
                    ],
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now()
                    }
                },
                dialogVisible: false
			}
		},
		components: {
			OrgSelect
		},
		methods: {
            // 上传
            onSubmit(formName){
                if(this.sureList.length <= 0) {
                    this.$message('请先选中机构');
                    return
                }
                this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					}
				});
            },
            save() {
                autoSchoolVote({
                    start: this.ruleForm.start_end_time[0].getTime()/1000,
                    end: this.ruleForm.start_end_time[1].getTime()/1000,
                    ids: this.sureList.map(item=>item.org_id),
                    num: this.ruleForm.num,
				}).then((res) => {
					this.$message(res.msg);
				}).catch((err) => {
					console.log(err, 222)
				})
            },
            // 删除机构
            deleteList(index) {
                this.list.splice(index, 1)
                this.sureList = this.list
            },
            // 确定选中机构
            addOrg() {
                if(this.list.length > 10) {
                    this.$message('最多可选中十个机构');
                    return false;
                }
                this.dialogVisible = false
                this.sureList = this.list
            },
            // 选中机构
            selectOrg(list) {
                this.list = list
            },
            close() {
                this.dialogVisible = false
            }
		}
	};
</script>
<style lang="less" scoped>
.width100 {
    display: flex;
    justify-content: center;
    font-size: 14px;
}
.widthblod {
    padding: 0;
    font-weight: 600;
}
.width20 {
    box-sizing: border-box;
    width: 25%;
    padding: 0 10px;
    text-align: center;
}
.card {
    max-width: 900px;
    margin: 10px 0;
    border: 1px solid #EBEEF5;
    background-color: #FFF;
    color: #303133;
}
</style>
